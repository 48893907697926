<template>
    <div class="main-box">
        <div class="shell">
            <div id="img-box">
                <img src="../assets/1.png" alt=""
                    style="border-radius: 15px 0 0 15px; box-shadow: 0px 15px 40px #7f7f7f;">
            </div>
            <form class="form" @submit.prevent>
                <div id="form-body">

                    <div id="welcome-lines">
                        <div id="w-line-1">登录</div>
                        <div id="w-line-2">出租屋、网约房智慧大屏</div>
                    </div>

                    <div class="msg">
                        {{ message }}
                    </div>

                    <div id="input-area">
                        <div class="f-inp">
                            <input type="text" v-model="form.username" placeholder="请输入账号">
                        </div>

                        <div class="f-inp">
                            <div class="password">
                                <input type="password" v-model="form.password" placeholder="请输入密码">
                            </div>
                        </div>

                        <div class="f-inputCode">
                            <div class="f-inp">
                                <input type="text" v-model="form.captcha" placeholder="请输入验证码">
                            </div>
                            <div style="margin-top: 5px;">
                                <img v-if="requestCodeSuccess" :src="randCodeImage" @click="handleChangeCheckCode" />
                                <img v-else src="../assets/checkcode.png" @click="handleChangeCheckCode" />
                            </div>
                        </div>

                    </div>

                    <div id="submit-button-cvr">
                        <button type="submit" @click="sub" id="submit-button" style="width: 318px;">登录</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                username: '',
                password: '',
                checkKey: '',
                captcha: ''
            },
            requestCodeSuccess: false,
            randCodeImage: '', // 验证码
            message: ''
        }
    },
    created() {
        this.handleChangeCheckCode()
    },
    methods: {
        async sub() {
            if (this.form.username !== '' && this.form.password !== '' && this.form.captcha !== '') {
                const res = await this.$http.post("/bigScreen/query/login", this.form)
                if (res.data.code == 200) {
                    await this.$store.commit('setTokens', res.data.result.bigScreenToken)
                    // await this.$store.dispatch('login', this.form)
                    // 跳转主页
                    this.$router.push('/')
                } else {
                    this.message = res.data.message
                    this.handleChangeCheckCode()
                }
            } else {
                this.message = '请输入账号、密码、验证码'
            }
        },
        /**刷新验证码*/
        handleChangeCheckCode() {
            this.form.checkKey = new Date().getTime();
            this.form.captcha = ''
            this.$http.get(`/sys/randomImage/${this.form.checkKey}`).then(res => {
                if (res.data.success) {
                    this.randCodeImage = res.data.result
                    this.requestCodeSuccess = true
                } else {
                    this.requestCodeSuccess = false
                }
            }).catch(() => {
                this.requestCodeSuccess = false
            })
        },
    }
}
</script>

<style lang="scss">
* {
    padding: 0;
    margin: 0;
    outline: none;
}

.main-box {
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: url(../assets/jeecg_bg.png);
}

.shell {
    form {
        position: relative;
    }
}

.shell {
    display: flex;
    justify-content: center;
}

.form {
    width: 562px;
    height: 520px;
    background-color: #fff;
    box-shadow: 0px 15px 40px #7f7f7f;
    border-radius: 0 15px 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

#img-box {
    width: 400px;
    height: 520px;
}

#img-box img {
    height: 100%;
}

#form-body {
    width: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.f-inputCode {
    display: flex;
}

#welcome-lines {
    width: 100%;
    text-align: center;
    line-height: 1;
}

#w-line-1 {
    color: #000;
    font-size: 30px;
}

#w-line-2 {
    color: #000;
    font-size: 20px;
    margin-top: 17px;
}

.msg {
    margin: 15px 0;
    color: red;
}

#input-area {
    width: 100%;
    // margin-top: 40px;
}

.f-inp {
    padding: 13px 25px;
    border: 2px solid #6e6d6d;
    line-height: 1;
    border-radius: 20px;
    margin-bottom: 15px;
}

.f-inp input {
    width: 100%;
    font-size: 14px;
    padding: 0;
    margin: 0;
    border: 0;
}

.f-inp input::placeholder {
    color: #b9b9b9;
}

#submit-button-cvr {
    margin-top: 20px;
}

#submit-button {
    display: block;
    width: 100%;
    color: #fff;
    font-size: 14px;
    margin: 0;
    padding: 14px 40px;
    border: 0;
    background-color: #409EFF;
    border-radius: 25px;
    line-height: 1;
    cursor: pointer;
}

#forgot-pass {
    text-align: center;
    margin-top: 10px;
}

#forgot-pass a {
    color: #868686;
    font-size: 12px;
    text-decoration: none;
}
</style>